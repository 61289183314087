import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const LIMIT = 50;
const DEBOUNCE_MS = 500;
const DEFAULT_URL = `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/?limit=${LIMIT}`;
const SEARCH_URL = (search) =>
    `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/?limit=${LIMIT}&search=${encodeURIComponent(
        search
    )}`;

const Organizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const debounceRef = useRef(-1);
    const history = useNavigate();

    if (localStorage.getItem("accessToken") == null) history("/");

    useEffect(() => {
        refreshToken();
        getOrganizations();
    }, []);

    useEffect(() => {
        clearInterval(debounceRef.current);
        debounceRef.current = setTimeout(() => getOrganizations(), DEBOUNCE_MS);
    }, [searchString]);

    const refreshToken = async () => {
        try {
            await axios
                .post(
                    "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/",
                    { refresh: localStorage.getItem("refreshToken") }
                )

                .then((response) => {
                    const newAccessToken = response.data.access;
                    const newRefreshToken = response.data.refresh;
                    localStorage.setItem("accessToken", newAccessToken);
                    localStorage.setItem("refreshToken", newRefreshToken);
                });
        } catch (error) {
            console.log(error);
            if (error.response) {
                history("/");
            }
        }
    };

    const getOrganizations = async (newUrl) => {
        const currentDate = new Date();
        if (localStorage.getItem("expire") < currentDate) await refreshToken();

        try {
            const url =
                newUrl ??
                (searchString ? SEARCH_URL(searchString) : DEFAULT_URL);
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            console.log(response);
            setOrganizations(response.data.results);
            setNextPage(response.data.next);
            setPreviousPage(response.data.previous);
        } catch (error) {
            console.log(error);
            if (error.status === 401) alert(error.data.detail);
        }
    };

    const deleteOrganization = async (id) => {
        const currentDate = new Date();
        if (localStorage.getItem("expire") < currentDate) await refreshToken();
        await axios.delete(
            `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/${id}/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            }
        );
        setOrganizations([]);
        await getOrganizations();
    };

    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container  mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <a href="/dashboard">Главная</a>
                        </li>
                        <li className="is-active">
                            <a href="#" aria-current="page">
                                Организации
                            </a>
                        </li>
                    </ul>
                </nav>

                <div className="block">
                    <div className="field">
                        <label className="label has-text-white">
                            Поиск организации
                        </label>
                        <div className="control">
                            <input
                                type="text"
                                className="input has-text-left"
                                placeholder="Начните вводить название организации..."
                                onChange={(e) => {
                                    setSearchString(e.currentTarget.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-2 is-flex is-justify-content-space-between">
                    {previousPage && (
                        <button
                            className="button login-button m-0"
                            onClick={() => getOrganizations(previousPage)}
                        >
                            Предыдущая страница
                        </button>
                    )}
                    {nextPage && (
                        <button
                            className="button login-button m-0"
                            onClick={() => getOrganizations(nextPage)}
                        >
                            Следующая страница
                        </button>
                    )}
                </div>
                <div className="table-container">
                    <table className="table  is-fullwidth">
                        <thead>
                            <tr>
                                <th>Наименование организации</th>
                                <th>Количество лицензий</th>

                                <th>Общее количество ключей </th>
                                <th>Количество активированных ключей</th>
                                <th>Закончившиеся ключи</th>
                                <th>Специалист отдела продаж</th>
                                <th>
                                    Открыть подробную информацию о организации
                                </th>

                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map((o) => (
                                <tr
                                    key={o.name}
                                    className={
                                        o.keys_expired > 0
                                            ? "has-background-danger"
                                            : ""
                                    }
                                >
                                    <td>{o.name}</td>
                                    <td>{o.licenses_count}</td>

                                    <td>{o.keys_amount}</td>
                                    <td>{o.keys_activated}</td>
                                    <td>{o.keys_expired}</td>
                                    <td>{o.sales_specialist_org}</td>
                                    <td>
                                        <a
                                            href={"/organization?id=" + o.id}
                                            className="button login-button "
                                        >
                                            Открыть
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            className="button login-button"
                                            onClick={() =>
                                                deleteOrganization(o.id)
                                            }
                                        >
                                            Удалить
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <a href={"/add_organization"} className="button login-button ">
                    Добавить организацию
                </a>
            </div>
        </section>
    );
};

export default Organizations;
