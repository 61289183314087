import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { render } from "@testing-library/react";
import jwt_decode from "jwt-decode";

const AddLicense = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [name, setName] = useState("");
    const [inn, setInn] = useState("");
    const [contact, setContact] = useState("");
    const [phone, setPhone] = useState("");

    const [msg, setMsg] = useState("");
    const [nameError, setNameError] = useState("");

    const history = useNavigate();

    if (localStorage.getItem("accessToken") == null) history("/");

    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            //alert()
            await axios
                .post(
                    "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/",
                    { refresh: localStorage.getItem("refreshToken") }
                )

                .then((response) => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem("accessToken", newAccessToken);

                    localStorage.setItem("refreshToken", newRefreshToken);
                });
        } catch (error) {
            console.log(error);
            if (error.status === 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    };

    const Send = async () => {
        if (name.length < 1) {
            setMsg("заполните название");
            return 0;
        }
        try {
            const response = await axios.post(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/",
                {
                    name: name,
                    inn: inn,
                    email: contact,
                    phone: phone,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );

            if (response.status !== 201) return;
            console.log(response);

            history(`/organization?id=${response.data.pk}`);
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
                setNameError(error.response.data.name);
            }
        }
    };
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <a href="/dashboard">Главная</a>
                        </li>
                        <li>
                            <a href="/organizations">Организации</a>
                        </li>
                        <li className="is-active">
                            <a href="#" aria-current="page">
                                Добавить организацию
                            </a>
                        </li>
                    </ul>
                </nav>
                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth">
                    <thead>
                        <tr>
                            <th>Наименование</th>
                            <th>ИНН</th>

                            <th>e-mail</th>
                            <th>Контактный телефон</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="is-flex is-flex-direction-column">
                                    <input
                                        type="label"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <span className="has-text-danger">{nameError}</span>
                                </div>
                            </td>
                            <td>
                                <input
                                    type="label"
                                    id="inn"
                                    value={inn}
                                    onChange={(e) => setInn(e.target.value)}
                                />
                            </td>

                            <td>
                                <input
                                    type="label"
                                    id="contact"
                                    value={contact}
                                    onChange={(e) => setContact(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="label"
                                    id="contact"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <a href={"/organizations"} className="button login-button ">
                    Отмена
                </a>
                <a id="add_org" onClick={Send} className="button login-button ">
                    Добавить
                </a>
            </div>
        </section>
    );
};

export default AddLicense;
