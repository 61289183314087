import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { compareAsc } from "date-fns";
import { getLicenseTypes } from "../api";

const License = () => {
    const [searchParams] = useSearchParams();

    const [keys, setKeys] = useState([]);
    const [login, setLogin] = useState("");
    const [pwd, setPwd] = useState("");
    const [clientLicenseType, setClientLicenseType] = useState("edu");
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const [msg, setMsg] = useState("");

    const history = useNavigate();

    if (localStorage.getItem("accessToken") == null) history("/");

    useEffect(() => {
        refreshToken();
        getLicenseTypes().then((response) =>
            setLicenseTypes(response.data.items)
        );
        getLicense();
    }, []);

    const refreshToken = async () => {
        try {
            //alert()
            await axios
                .post(
                    "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/",
                    { refresh: localStorage.getItem("refreshToken") }
                )

                .then((response) => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem("accessToken", newAccessToken);

                    localStorage.setItem("refreshToken", newRefreshToken);
                });
        } catch (error) {
            console.log(error);
            if (error.status === 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    };

    const getLicense = async () => {
        const currentDate = new Date();
        if (localStorage.getItem("expire") < currentDate) await refreshToken();

        try {
            const response = await axios.get(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/" +
                    searchParams.get("id") +
                    "/",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            setClientLicenseType(response.data.client_license_type);
            setLogin(response.data.login);
            setPwd(response.data.password);
            setOrganizationName(response.data.organization);
            setOrganizationId(response.data.organization_id);

            setKeys(response.data.keys);
        } catch (error) {
            console.log(error);
        }
    };

    const Send = () => {
        axios
            .post(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/" +
                    searchParams.get("id") +
                    "/update_license/",
                {
                    client_license_type: clientLicenseType,
                    login: login,
                    password: pwd,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )

            .then((response) => {
                if (response.status !== 200) return;
                console.log(response);

                window.location.reload();
            })

            .catch((error) => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            });
    };
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <a href="/dashboard">Главная</a>
                        </li>
                        <li>
                            <a href="/organizations">Организации</a>
                        </li>
                        <li>
                            <a href={`/organization?id=${organizationId}`}>
                                {organizationName}
                            </a>
                        </li>
                        <li className="is-active">
                            <a href="#" aria-current="page">
                                {login}
                            </a>
                        </li>
                    </ul>
                </nav>
                <h1>{organizationName + "/" + login}</h1>
                <div className="info-table-container">
                    <p className="has-text-centered">{msg}</p>
                    <table className="table  is-fullwidth">
                        <thead>
                            <tr>
                                <th>Тип лицензии</th>
                                <th>Логин</th>
                                <th>Пароль</th>

                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <select
                                        id="cl_type"
                                        value={clientLicenseType}
                                        onChange={(e) =>
                                            setClientLicenseType(e.target.value)
                                        }
                                    >
                                        {licenseTypes.map((licenseType) => (
                                            <option
                                                value={licenseType.type}
                                                key={licenseType.type}
                                            >
                                                {licenseType.name}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <input
                                        type="label"
                                        id="name"
                                        value={login}
                                        onChange={(e) =>
                                            setLogin(e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="label"
                                        id="pwd"
                                        value={pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                    />
                                </td>

                                <td>
                                    <a
                                        onClick={() => setModalOpen(true)}
                                        className="button login-button "
                                    >
                                        Изменить
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="table-container">
                    <table className="table  is-fullwidth">
                        <thead>
                            <tr>
                                <th>Номер ключа</th>
                                <th>Мак адресс</th>
                                <th>Активирован</th>

                                <th>Срок истечения</th>
                                <th>Удаление/продление</th>
                            </tr>
                        </thead>
                        <tbody>
                            {keys.map((o, i) => (
                                <tr
                                    key={o.id}
                                    className={
                                        compareAsc(
                                            new Date(),
                                            new Date(o.expires_at)
                                        ) > 0
                                            ? "has-background-danger"
                                            : ""
                                    }
                                >
                                    <td> {i + 1}</td>
                                    <td> {o.mac_address}</td>
                                    <td>
                                        {" "}
                                        {o.validated
                                            ? "Активирован"
                                            : "Не активирован"}
                                    </td>

                                    <td>
                                        {" "}
                                        {new Date(
                                            o.expires_at
                                        ).toLocaleDateString("ru-Ru")}
                                    </td>
                                    <td>
                                        <a
                                            href={
                                                "/key?id=" +
                                                o.id +
                                                "&l_id=" +
                                                searchParams.get("id")
                                            }
                                            className="button login-button "
                                        >
                                            Открыть
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <a
                    href={"/add_keys?l_id=" + searchParams.get("id")}
                    className="button login-button "
                >
                    Добавить ключи
                </a>
                <a
                    href={"/longate_license?l_id=" + searchParams.get("id")}
                    className="button login-button "
                    id="add_org"
                >
                    Продлить ключи
                </a>
            </div>
            <div className={"modal" + (modalOpen ? " is-active" : "")}>
                <div
                    className="modal-background"
                    onClick={() => setModalOpen(false)}
                ></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Сохранение</p>
                        <button
                            className="delete"
                            aria-label="close"
                            onClick={() => setModalOpen(false)}
                        ></button>
                    </header>
                    <section className="modal-card-body">
                        Вы уверены что хотите изменить данные лицензии?
                    </section>
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button
                                className="button is-success"
                                onClick={() => {
                                    setModalOpen(false);
                                    Send();
                                }}
                            >
                                Да
                            </button>
                            <button
                                className="button"
                                onClick={() => setModalOpen(false)}
                            >
                                Нет
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        </section>
    );
};

export default License;
