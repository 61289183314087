import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const LongateLicense = () => {



    const [searchParams, setSearchParams] = useSearchParams();
    const [msg, setMsg] = useState('');

    const [login, setLogin] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [organizationId, setOrganizationId] = useState(0);

    const history = useNavigate();
    const [expDate, setExpDate] = useState(new Date());



    if (localStorage.getItem('accessToken') == null) history("/");

    useEffect(() => {
        refreshToken();
        getLicense(searchParams.get("l_id"));
    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error);
            if (error.status=== 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    }

    const getLicense = async (id) => {
        const currentDate = new Date();
        if (localStorage.getItem('expire') < currentDate) await refreshToken();

        const response = await axios.get(`https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/${id}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
            }
        )
            .then(response => {
                setLogin(response.data.login);
                setOrganizationName(response.data.organization);
                setOrganizationId(response.data.organization_id);
            })
            .catch(error => {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {


                }
            });


    }

    const Send = ()=>{
        expDate.setHours(23,59,59)
        axios.patch('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/'+searchParams.get("l_id")+'/prolongate_keys/', {

                expires_at:expDate.toISOString(),



            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            .then(response => {

                if (response.status !== 200) return
                console.log(response);


                history("/license?id="+searchParams.get("l_id"));
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })
    }
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li><a href="/dashboard">Главная</a></li>
                        <li><a href="/organizations">Организации</a></li>
                        <li><a href={`/organization?id=${organizationId}`}>{organizationName}</a></li>
                        <li><a href={`/license?id=${searchParams.get("l_id")}`}>{login}</a></li>
                        <li className="is-active"><a href="#" aria-current="page">Продлить ключи</a></li>
                    </ul>
                </nav>
                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth" >
                    <thead>
                    <tr>


                        <th>Дата истечения</th>




                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                        <td> <DatePicker selected={expDate} onChange={(date) => setExpDate(date)} dateFormat="dd/MM/yyyy" /> </td>


                    </tr>
                    </tbody>
                </table>

                <a id="" onClick={Send} className="button login-button " >Продлить</a>
            </div>
        </section>
    )


}

export default LongateLicense;