import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddKeys = () => {
    const [searchParams] = useSearchParams();
    const [msg, setMsg] = useState("");
    const history = useNavigate();
    const [expDate, setExpDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [infinite, setInfinite] = useState(false);

    const [login, setLogin] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState(0);

    if (localStorage.getItem("accessToken") == null) history("/");

    useEffect(() => {
        refreshToken();
        getLicense(searchParams.get("l_id"));
    }, []);

    const refreshToken = async () => {
        try {
            //alert()
            await axios
                .post(
                    "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/",
                    { refresh: localStorage.getItem("refreshToken") }
                )

                .then((response) => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem("accessToken", newAccessToken);

                    localStorage.setItem("refreshToken", newRefreshToken);
                });
        } catch (error) {
            if (error.status === 401) alert(error.data.detail);
            console.log(error);
            if (error.response) {
                history("/");
            }
        }
    };

    const getLicense = async (id) => {
        const currentDate = new Date();
        if (localStorage.getItem("expire") < currentDate) await refreshToken();

        const response = await axios
            .get(
                `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/${id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setLogin(response.data.login);
                setOrganizationName(response.data.organization);
                setOrganizationId(response.data.organization_id);
            })
            .catch((error) => {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {
                }
            });
    };

    const Send = () => {
        expDate.setHours(23, 59, 59);
        axios
            .patch(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/" +
                    searchParams.get("l_id") +
                    "/add_keys/",
                {
                    expires_at: expDate.toISOString(),
                    number_of_keys: parseInt(amount),

                    activate_from: startDate.toISOString(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )

            .then((response) => {
                if (response.status !== 200) return;
                console.log(response);

                history("/license?id=" + searchParams.get("l_id"));
            })

            .catch((error) => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            });
    };
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <a href="/dashboard">Главная</a>
                        </li>
                        <li>
                            <a href="/organizations">Организации</a>
                        </li>
                        <li>
                            <a href={`/organization?id=${organizationId}`}>
                                {organizationName}
                            </a>
                        </li>
                        <li>
                            <a href={`/license?id=${searchParams.get("l_id")}`}>
                                {login}
                            </a>
                        </li>
                        <li className="is-active">
                            <a href="#" aria-current="page">
                                Добавить ключи
                            </a>
                        </li>
                    </ul>
                </nav>
                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth">
                    <thead>
                        <tr>
                            <th>Количество </th>
                            <th>Дата активации</th>
                            <th>Бесконечный</th>
                            <th>Дата истечения</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </td>

                            <td>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    defaultChecked={infinite}
                                    onChange={(e) => {
                                        setInfinite(e.currentTarget.checked);
                                        if (e.currentTarget.checked) {
                                            setExpDate(new Date(2124, 0, 1));
                                        } else {
                                            setExpDate(new Date());
                                        }
                                    }}
                                />
                            </td>
                            <td>
                                <DatePicker
                                    disabled={infinite}
                                    selected={expDate}
                                    onChange={(date) => setExpDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <a id="" onClick={Send} className="button login-button ">
                    Добавить
                </a>
            </div>
        </section>
    );
};

export default AddKeys;
