import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Fuse from "fuse.js";
import { getLicenseTypes } from "../api";

const Organization = () => {
    const [searchParams] = useSearchParams();

    const [licenses, setLicenses] = useState([]);
    const [licenseTypes, setLicenseTypes] = useState(new Map());
    const [searcher, setSearcher] = useState(() => new Fuse([]));
    const [searchString, setSearchString] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const history = useNavigate();

    const [name, setName] = useState("");
    const [inn, setInn] = useState("");
    const [contact, setContact] = useState("");
    const [phone, setPhone] = useState("");

    const [msg, setMsg] = useState("");

    if (
        localStorage.getItem("accessToken") === null ||
        localStorage.getItem("accessToken") === ""
    )
        history("/");

    useEffect(() => {
        refreshToken();
        getLicenseTypes().then((response) => {
            const items = response.data.items;
            const pairs = items.map((item) => [item.type, item.name]);
            setLicenseTypes(new Map(pairs));
        });
        getOrganization();
    }, []);

    const refreshToken = async () => {
        try {
            //alert()
            await axios
                .post(
                    "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/",
                    { refresh: localStorage.getItem("refreshToken") }
                )

                .then((response) => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem("accessToken", newAccessToken);

                    localStorage.setItem("refreshToken", newRefreshToken);
                });
        } catch (error) {
            console.log(error);
            if (error.status === 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    };

    const getOrganization = async () => {
        const currentDate = new Date();
        if (localStorage.getItem("expire") < currentDate) await refreshToken();

        const response = await axios
            .get(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/" +
                    searchParams.get("id") +
                    "/",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);

                setName(response.data.name ?? "");
                setContact(response.data.email ?? "");
                setPhone(response.data.phone ?? "");
                setInn(response.data.inn ?? "");

                setLicenses(response.data.licenses);
                setSearcher(
                    new Fuse(response.data.licenses, {
                        keys: ["login", "password"],
                    })
                );
            })
            .catch((error) => {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {
                }
            });
    };
    const Send = () => {
        if (
            name.length < 1 ||
            inn.length < 1 ||
            contact.length < 1 ||
            phone.length < 1
        ) {
            setMsg("заполните все поля");
            return 0;
        }
        axios
            .post(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/" +
                    searchParams.get("id") +
                    "/update_organization/",
                {
                    name: name,
                    inn: inn,
                    email: contact,
                    phone: phone,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                if (response.status !== 200) return;
                console.log(response);
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            });
    };

    const deleteLicence = async (id) => {
        await axios.delete(
            `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/${id}/`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            }
        );
        await getOrganization();
    };

    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <a href="/dashboard">Главная</a>
                        </li>
                        <li>
                            <a href="/organizations">Организации</a>
                        </li>
                        <li className="is-active">
                            <a href="#" aria-current="page">
                                {name}
                            </a>
                        </li>
                    </ul>
                </nav>
                <h1>{name}</h1>
                <div className="info-table-container">
                    <p className="has-text-centered">{msg}</p>
                    <table className="table  is-fullwidth">
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th>ИНН</th>

                                <th>e-mail</th>
                                <th>Контактный телефон</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input
                                        type="label"
                                        id="name"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="label"
                                        id="inn"
                                        value={inn}
                                        onChange={(e) => setInn(e.target.value)}
                                    />
                                </td>

                                <td>
                                    <input
                                        type="label"
                                        id="contact"
                                        value={contact}
                                        onChange={(e) =>
                                            setContact(e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="label"
                                        id="contact"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <a
                                        id=""
                                        onClick={() => setModalOpen(true)}
                                        className="button login-button "
                                    >
                                        Сохранить
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="block">
                    <div className="field">
                        <label className="label has-text-white">
                            Поиск лицензии
                        </label>
                        <div className="control">
                            <input
                                type="text"
                                className="input has-text-left"
                                placeholder="Начните вводить название лицензии..."
                                onChange={(e) =>
                                    setSearchString(e.currentTarget.value)
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <table className="table  is-fullwidth">
                        <thead>
                            <tr>
                                <th>Тип лицензии</th>
                                <th>Логин</th>
                                <th>Пароль</th>

                                <th>Общее количество ключей</th>
                                <th>Количество активированных ключей</th>
                                <th>Закончившиеся ключи</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(searchString.length === 0
                                ? licenses
                                : searcher
                                      .search(searchString)
                                      .map((o) => o.item)
                            ).map((o) => (
                                <tr
                                    key={o.id}
                                    className={
                                        o.keys_expired > 0
                                            ? "has-background-danger"
                                            : ""
                                    }
                                >
                                    <td> {licenseTypes.get(o.client_license_type) ?? o.client_license_type}</td>
                                    <td> {o.login}</td>
                                    <td> {o.password}</td>

                                    <td> {o.keys_amount}</td>
                                    <td> {o.keys_activated}</td>
                                    <td> {o.keys_expired}</td>
                                    <td>
                                        <a
                                            href={
                                                "/license?id=" +
                                                o.id +
                                                "&org_id=" +
                                                searchParams.get("id") +
                                                "&name=" +
                                                name
                                            }
                                            className="button login-button "
                                        >
                                            Открыть
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            className="button login-button"
                                            onClick={() => deleteLicence(o.id)}
                                        >
                                            Удалить
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <a
                    href={"/add_license?org_id=" + searchParams.get("id")}
                    className="button login-button "
                >
                    Добавить лицензию
                </a>
            </div>
            <div className={"modal" + (modalOpen ? " is-active" : "")}>
                <div
                    className="modal-background"
                    onClick={() => setModalOpen(false)}
                ></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Сохранение</p>
                        <button
                            className="delete"
                            aria-label="close"
                            onClick={() => setModalOpen(false)}
                        ></button>
                    </header>
                    <section className="modal-card-body">
                        Вы уверены что хотите изменить данные организации?
                    </section>
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button
                                className="button is-success"
                                onClick={() => {
                                    setModalOpen(false);
                                    Send();
                                }}
                            >
                                Да
                            </button>
                            <button
                                className="button"
                                onClick={() => setModalOpen(false)}
                            >
                                Нет
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        </section>
    );
};

export default Organization;
