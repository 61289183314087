import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { generateLogin, getLicenseTypes } from "../api";

const AddOrganization = () => {
    const [searchParams] = useSearchParams();

    const [login, setLogin] = useState("");
    const [clientLicenseType, setClientLicenseType] = useState("edu");
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [organizationName, setOrganizationName] = useState("");

    const [msg, setMsg] = useState("");
    const [loginError, setLoginError] = useState("");

    const history = useNavigate();

    if (localStorage.getItem("accessToken") == null) history("/");

    useEffect(() => {
        refreshToken();
        getLicenseTypes().then((response) =>
            setLicenseTypes(response.data.items)
        );
        getOrganization();
    }, []);

    const refreshToken = async () => {
        try {
            //alert()
            await axios
                .post(
                    "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/",
                    { refresh: localStorage.getItem("refreshToken") }
                )

                .then((response) => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem("accessToken", newAccessToken);

                    localStorage.setItem("refreshToken", newRefreshToken);
                });
        } catch (error) {
            console.log(error);
            if (error.status === 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    };

    const getOrganization = async () => {
        const currentDate = new Date();
        if (localStorage.getItem("expire") < currentDate) await refreshToken();

        const response = await axios
            .get(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/" +
                    searchParams.get("org_id") +
                    "/",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                console.log(response.data);

                setOrganizationName(response.data.name);
            })
            .catch((error) => {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {
                }
            });
    };

    const generateLicenseLogin = async () => {
        const response = await generateLogin();
        setLogin(response.data.login);
    };

    const Send = async () => {
        if (login.length < 1 || clientLicenseType.length < 1) {
            setMsg("заполните все поля");
            return 0;
        }

        try {
            const response = await axios.post(
                "https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/",
                {
                    organization: searchParams.get("org_id"),
                    login: login,
                    client_license_type: clientLicenseType,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );

            if (response.status !== 201) return;
            console.log(response);

            history("/organization?id=" + searchParams.get("org_id"));
        } catch (error) {
            console.error(error);
            if (error.response) {
                setMsg(error.response.data.msg);
                setLoginError(error.response.data.login);
            }
        }
    };
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <a href="/dashboard">Главная</a>
                        </li>
                        <li>
                            <a href="/organizations">Организации</a>
                        </li>
                        <li>
                            <a
                                href={`/organization?id=${searchParams.get(
                                    "org_id"
                                )}`}
                            >
                                {organizationName}
                            </a>
                        </li>
                        <li className="is-active">
                            <a href="#" aria-current="page">
                                Добавить лицензию
                            </a>
                        </li>
                    </ul>
                </nav>
                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth">
                    <thead>
                        <tr>
                            <th>Логин</th>

                            <th>Тип лицензии</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="is-flex is-flex-direction-column">
                                    <input
                                        type="label"
                                        id="name"
                                        className="w-40"
                                        value={login}
                                        onChange={(e) => setLogin(e.target.value)}
                                    />
                                    <span className="has-text-danger">{loginError}</span>
                                    <button
                                        className="button login-button"
                                        onClick={() => generateLicenseLogin()}
                                    >
                                        Сгенерировать
                                    </button>
                                </div>
                            </td>
                            <td>
                                <select
                                    id="cl_type"
                                    value={clientLicenseType}
                                    onChange={(e) =>
                                        setClientLicenseType(e.target.value)
                                    }
                                >
                                    {licenseTypes.map((licenseType) => (
                                        <option
                                            value={licenseType.type}
                                            key={licenseType.type}
                                        >
                                            {licenseType.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <a
                    href={"/organization?id=" + searchParams.get("org_id")}
                    className="button login-button "
                >
                    Отмена
                </a>
                <a id="add_org" onClick={Send} className="button login-button ">
                    Добавить
                </a>
            </div>
        </section>
    );
};

export default AddOrganization;
