import axios from "axios";

const ROOT_URL = `https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin`;

export const refreshToken = async (force = false) => {
    // const history = useNavigate();
    const currentDate = new Date();
    if (!force && localStorage.getItem("expire") > currentDate) return;

    try {
        await axios
            .post(`${ROOT_URL}/refresh/`, {
                refresh: localStorage.getItem("refreshToken"),
            })

            .then((response) => {
                const newAccessToken = response.data.access;
                const newRefreshToken = response.data.refresh;
                localStorage.setItem("accessToken", newAccessToken);
                localStorage.setItem("refreshToken", newRefreshToken);
            });
    } catch (error) {
        console.log(error);
        if (error.response) {
            // history("/");
        }
    }
};

export const getLicenseTypes = async () => {
    await refreshToken();

    return await axios.get(`${ROOT_URL}/licenses-types/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    });
};

export const generateLogin = async () => {
    await refreshToken();

    return await axios.post(`${ROOT_URL}/generate-login/`, null, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    });
};
